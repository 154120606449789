// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-editorial-policies-editorial-board-and-staff-js": () => import("./../../../src/pages/about/editorial-policies/editorial-board-and-staff.js" /* webpackChunkName: "component---src-pages-about-editorial-policies-editorial-board-and-staff-js" */),
  "component---src-pages-about-editorial-policies-index-js": () => import("./../../../src/pages/about/editorial-policies/index.js" /* webpackChunkName: "component---src-pages-about-editorial-policies-index-js" */),
  "component---src-pages-about-editorial-policies-micropublication-biology-editorial-board-js": () => import("./../../../src/pages/about/editorial-policies/micropublication-biology-editorial-board.js" /* webpackChunkName: "component---src-pages-about-editorial-policies-micropublication-biology-editorial-board-js" */),
  "component---src-pages-about-editorial-policies-publishing-information-js": () => import("./../../../src/pages/about/editorial-policies/publishing-information.js" /* webpackChunkName: "component---src-pages-about-editorial-policies-publishing-information-js" */),
  "component---src-pages-about-for-reviewers-js": () => import("./../../../src/pages/about/for-reviewers.js" /* webpackChunkName: "component---src-pages-about-for-reviewers-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-sharing-policy-js": () => import("./../../../src/pages/data-sharing-policy.js" /* webpackChunkName: "component---src-pages-data-sharing-policy-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-review-panels-js": () => import("./../../../src/pages/review-panels.js" /* webpackChunkName: "component---src-pages-review-panels-js" */),
  "component---src-pages-submit-new-article-js": () => import("./../../../src/pages/submit-new-article.js" /* webpackChunkName: "component---src-pages-submit-new-article-js" */),
  "component---src-templates-archives-js": () => import("./../../../src/templates/archives.js" /* webpackChunkName: "component---src-templates-archives-js" */),
  "component---src-templates-article-index-template-js": () => import("./../../../src/templates/articleIndexTemplate.js" /* webpackChunkName: "component---src-templates-article-index-template-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-correction-page-js": () => import("./../../../src/templates/correction-page.js" /* webpackChunkName: "component---src-templates-correction-page-js" */),
  "component---src-templates-for-authors-js": () => import("./../../../src/templates/for-authors.js" /* webpackChunkName: "component---src-templates-for-authors-js" */),
  "component---src-templates-integrations-index-template-js": () => import("./../../../src/templates/integrationsIndexTemplate.js" /* webpackChunkName: "component---src-templates-integrations-index-template-js" */),
  "component---src-templates-main-page-js": () => import("./../../../src/templates/mainPage.js" /* webpackChunkName: "component---src-templates-main-page-js" */),
  "component---src-templates-news-index-template-js": () => import("./../../../src/templates/newsIndexTemplate.js" /* webpackChunkName: "component---src-templates-news-index-template-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-species-template-js": () => import("./../../../src/templates/speciesTemplate.js" /* webpackChunkName: "component---src-templates-species-template-js" */),
  "component---src-templates-taxonomy-template-js": () => import("./../../../src/templates/taxonomyTemplate.js" /* webpackChunkName: "component---src-templates-taxonomy-template-js" */)
}

