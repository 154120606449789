import { theme as defaultTheme } from "@chakra-ui/react";

const theme = {
  ...defaultTheme,
  font: {
    body: "Noto sans, sans-serif",
  },
  components: {
    ...defaultTheme.components,
    Popover: {
      ...defaultTheme.components.Popover,
      variants: {
        responsive: {
          popper: {
            maxWidth: "unset",
            width: "unset",
          },
        },
      },
    },
    Link: {
      ...defaultTheme.components.Link,
      baseStyle: {
        ...defaultTheme.components.Link.baseStyle,
        textDecoration: "underline",
      },
    },
  },
};

export default theme;
